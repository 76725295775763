    :root {
      --primary-1: rgba(17, 17, 17, 1); 
      --primary-2: rgba(34, 197, 94, 0.12); 
      --primary-3: rgb(138, 176, 153); 
      --primary-4: rgba(184, 223, 200, 1); 
      --primary-5: rgba(232, 74, 74, 1); 
      --primary-6: rgba(34, 197, 94, 0.29); 
      --primary-7: rgba(34, 197, 94, 1);
      --hero-bg-opacity: 0.09;
    }

    body[data-theme='primary-2'] {
      --primary-1: rgba(17, 17, 17, 1); 
      --primary-2: rgba(232, 74, 74, 0.09); 
      --primary-3: rgba(173, 140, 131, 1);
      --primary-4: rgba(237, 189, 176, 1); 
      --primary-5: rgba(34, 197, 94, 1); 
      --primary-6: rgba(232, 74, 74, 0.29);
      --primary-7: rgba(232, 74, 74, 1);
      --hero-bg-opacity: 0.04;
    }
