@font-face {
    font-family: 'icomoon';
    src: url('fonts/icomoon.eot?9x21az');
    src: url('fonts/icomoon.eot?9x21az#iefix') format('embedded-opentype'),
        url('fonts/icomoon.ttf?9x21az') format('truetype'),
        url('fonts/icomoon.woff?9x21az') format('woff'),
        url('fonts/icomoon.svg?9x21az#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Kode Mono';
    src: url('./fonts/KodeMono-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Kode Mono';
    src: url('./fonts/KodeMono-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Kode Mono';
    src: url('./fonts/KodeMono-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Kode Mono';
    src: url('./fonts/KodeMono-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

:root {
    --grid-size: 12px;
    --snake-size: 11px;
    --food-size: 19px;
    --game-width: 250px;
    --game-height: 350px;
}


::-webkit-scrollbar {
    width: 1px;
    height: 2px;
}

::-webkit-scrollbar-track {
    background: var(--primary-2);
}

::-webkit-scrollbar-thumb {
    background: var(--primary-7);
}

::selection {
    color: var(--primary-7);
    background: var(--primary-2)
}


body {

    button,
    a,
    .cursor-pointer {
        cursor: none !important;
    }
}


.not-hover-opacity:hover> :not(:hover) {
    opacity: 0.5;
}

.dockitem:hover {
    scale: 2.2;
    margin-inline: 1.5rem;
}

.dockitem:hover+.dockitem,
.dockitem:has(+ .dockitem:hover) {
    scale: 2;
    margin-inline: 0.8rem;
}

.dockitem:hover+.dockitem+.dockitem,
.dockitem:has(+ .dockitem + .dockitem:hover) {
    scale: 1.6;
    margin-inline: 0.5rem;
}

.dockitem:hover+.dockitem+.dockitem+.dockitem,
.dockitem:has(+ .dockitem + .dockitem + .dockitem:hover) {
    scale: 1.2;
    margin-inline: 0.2rem;
}

.dockitem__txt:after {
    --size: 2px;
    content: "";
    position: absolute;
    bottom: -1px;
    left: 50%;
    width: 0;
    height: 0;
    border: var(--size) solid transparent;
    border-top-color: var(--primary-6);
    border-bottom: 0;
    margin-left: calc(-1 * var(--size));
    margin-bottom: calc(-1 * var(--size));
}