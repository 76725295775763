[class^="icon-"]:before,
[class*=" icon-"]:before {
    z-index: 1;
    position: relative;
}

[class^="icon-"],
[class*=" icon-"] {
    font-family: 'icomoon';
}


.icon-ethereum:before {
  content: "\e900";
}
.icon-blockchain:before {
  content: "\e901";
}
.icon-theilu:before {
  content: "\e91e";
}
.icon-illuminati-one:before {
  content: "\e91c";
}
.icon-illuminati-two:before {
  content: "\e91d";
}
.icon-external-link:before {
  content: "\e91b";
}
.icon-bash-shell:before {
  content: "\e913";
}
.icon-typescript:before {
  content: "\e91a";
}
.icon-vim:before {
  content: "\e919";
}
.icon-node:before {
  content: "\e918";
}
.icon-cursor:before {
  content: "\e916";
}
.icon-pointer:before {
  content: "\e917";
}
.icon-sound-on:before {
  content: "\e914";
}
.icon-sound-off:before {
  content: "\e915";
}
.icon-mail-full:before {
  content: "\e910";
}
.icon-mail:before {
  content: "\e911";
}
.icon-discord:before {
  content: "\e912";
}
.icon-finger:before {
  content: "\e90f";
}
.icon-figma:before {
  content: "\e902";
}
.icon-css3:before {
  content: "\e906";
}
.icon-html5:before {
  content: "\e907";
}
.icon-git:before {
  content: "\e905";
}
.icon-agile:before {
  content: "\e908";
}
.icon-code:before {
  content: "\e909";
}
.icon-javascript:before {
  content: "\e90a";
}
.icon-reactjs:before {
  content: "\e90b";
}
.icon-scrum:before {
  content: "\e90c";
}
.icon-scss:before {
  content: "\e90d";
}
.icon-tailwindcss:before {
  content: "\e90e";
}
.icon-linkedin:before {
  content: "\e903";
}
.icon-upwork:before {
  content: "\e904";
}
.icon-github:before {
  content: "\e91f";
}
.icon-twitter:before {
  content: "\e920";
}
